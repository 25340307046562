// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-process-index-tsx": () => import("./../../../src/pages/process/index.tsx" /* webpackChunkName: "component---src-pages-process-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-templates-press-article-index-tsx": () => import("./../../../src/templates/pressArticle/index.tsx" /* webpackChunkName: "component---src-templates-press-article-index-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */)
}

