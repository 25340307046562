import React, { useState } from 'react'
import './index.scss'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'gatsby'
import HeadTylerKaru from '@images/svg/headTylerKaru'
import { SanityHeader } from '@utils/types'
import Hamburger from '@images/svg/hamburger'

type Props = {
  header: SanityHeader
}

const HeaderComponent = ({ header }: Props) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Navbar
      bg="header"
      expand={false}
      expanded={expanded}
      fixed="top"
      className={expanded ? 'navbar-expanded' : ''}
      onToggle={() => setExpanded(!expanded)}
      collapseOnSelect
    >
      <Navbar.Brand as={'div'}>
        <Link to="/" onClick={() => setExpanded(false)}>
          <HeadTylerKaru />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="h-toggle">
        <Hamburger expanded={expanded} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {header.links.map((item, index) => (
            <div key={index} className="nav-item">
              <Nav.Link
                onClick={() => setExpanded(false)}
                as={Link}
                to={item.slug.current}
                className="text-animation"
              >
                {item.title}
              </Nav.Link>
            </div>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default HeaderComponent
