import React, { useState, useEffect } from 'react'
import '@styles/index.scss'
import './index.scss'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
//@ts-ignore
import favicon from '@images/icon.png'
//@ts-ignore
import AntonRegular from '@styles/fonts/Anton/Anton-Regular.ttf'
//@ts-ignore
import Inter from '@styles/fonts/Inter/Inter-VariableFont_slnt,wght.ttf'
const Footer = loadable(() => import('./footer'))
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderComponent from './header'

const MainLayout = ({ children }) => {
  const { sanityHeader, sanityFooter } = useStaticQuery(query)

  return (
    <>
      <main>
        <Helmet>
          <link rel="icon" href={favicon} />
          <link
            rel="preload"
            as="font"
            href={AntonRegular}
            type="font/ttf"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            as="font"
            href={Inter}
            type="font/ttf"
            crossOrigin="anonymous"
          />
          <link
            href="https://cloud.typenetwork.com/projects/5820/fontface.css/"
            rel="stylesheet"
            type="text/css"
            crossOrigin="anonymous"
          />
        </Helmet>
        <div className="initial-anim initial-anim-light" />
        <div className="initial-anim initial-anim-dark" />
        <HeaderComponent header={sanityHeader} />
        <div className="content">{children}</div>
        <Footer footer={sanityFooter} />
      </main>
    </>
  )
}

export default MainLayout

const query = graphql`
  query {
    sanityHeader {
      brand {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      links {
        title
        slug {
          current
        }
      }
    }
    sanityFooter {
      brand {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      links {
        title
        slug {
          current
        }
      }
      _rawContact(resolveReferences: { maxDepth: 10 })
    }
  }
`
