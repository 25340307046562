import React from 'react'

type Props = {
  expanded: boolean
}

const Hamburger = ({ expanded }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="16"
    viewBox="0 0 38 16"
  >
    <g id="Group_19" data-name="Group 19" transform="translate(-1308 -50)">
      <rect
        id="Rectangle_7"
        data-name="Rectangle 7"
        width="23"
        height="3"
        transform={`translate(${expanded ? 1308 : 1323} 50)`}
        fill="#212121"
      />
      <rect
        id="Rectangle_12"
        data-name="Rectangle 12"
        width="38"
        height="3"
        transform="translate(1308 63)"
        fill="#212121"
      />
    </g>
  </svg>
)

export default Hamburger
