import React from 'react'

const HeadTylerKaru = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="219.227"
      height="44.788"
      viewBox="0 0 219.227 44.788"
    >
      <g
        id="Group_1"
        data-name="Group 1"
        transform="translate(-113.123 -239.434)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M748.939,276.808a11.494,11.494,0,0,0-3.679-1.721c-.364-.1-.739-.191-1.122-.268a17.22,17.22,0,0,0-2.035-.275c-.5-.037-1.015-.056-1.544-.056a14.963,14.963,0,0,0-6.1,1.115,9.421,9.421,0,0,0-3.9,3.019,8.943,8.943,0,0,0-1.629,3.914.319.319,0,0,0,.31.364l7.295.171a.315.315,0,0,0,.314-.233,2.865,2.865,0,0,1,1.128-1.667,4.175,4.175,0,0,1,2.48-.676,4.257,4.257,0,0,1,1.644.289,2.809,2.809,0,0,1,.651.37,2.2,2.2,0,0,1,.857,1.8c0,.025,0,.05,0,.075v.083c0,.033,0,.064,0,.1v3.668H732.188a1.719,1.719,0,0,0-.868.235c-.2.121-.4.248-.589.381a6.428,6.428,0,0,0-2.066,2.43,7.686,7.686,0,0,0-.73,3.129c-.008.167-.013.335-.013.508a7.722,7.722,0,0,0,1.088,4.268,6.648,6.648,0,0,0,3,2.524,10.919,10.919,0,0,0,4.395.834,10.614,10.614,0,0,0,3.2-.447,7.288,7.288,0,0,0,2.5-1.336l.017-.013a7.43,7.43,0,0,0,1.814-2.27h.2V300.4a.341.341,0,0,0,.341.341h7.349a.341.341,0,0,0,.341-.341v-17.31a7.478,7.478,0,0,0-.845-3.577A7.936,7.936,0,0,0,748.939,276.808Zm-5.273,14.723a3.972,3.972,0,0,1-.281,1.519,3.307,3.307,0,0,1-.235.475,1.775,1.775,0,0,1-.11.175,4.185,4.185,0,0,1-.936.988,4.393,4.393,0,0,1-.716.439,5.1,5.1,0,0,1-2.278.506,3.648,3.648,0,0,1-2.22-.641,2.083,2.083,0,0,1-.841-1.467h-.027v-1.256a3.1,3.1,0,0,1,3.1-3.1h4.545Z"
          transform="translate(-469.209 -26.753)"
          fill="inherit"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M863.562,274.7a8.91,8.91,0,0,0-.965-.187,8.619,8.619,0,0,0-1.181-.084,5.776,5.776,0,0,0-3.7,1.257,7.148,7.148,0,0,0-2.295,3.89h-.27v-4.547a.246.246,0,0,0-.246-.246h-7.827a.246.246,0,0,0-.246.246v25.427a.246.246,0,0,0,.246.246h8.063a.246.246,0,0,0,.246-.246V286.793a5.268,5.268,0,0,1,.658-2.666,4.676,4.676,0,0,1,1.806-1.789,5.2,5.2,0,0,1,2.582-.641,14.394,14.394,0,0,1,1.713.118,8.908,8.908,0,0,1,1.283.24.245.245,0,0,0,.312-.236v-6.885A.246.246,0,0,0,863.562,274.7Z"
          transform="translate(-559.964 -26.704)"
          fill="inherit"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M957.021,275.92h-7.885a.336.336,0,0,0-.335.336V290.6a4.938,4.938,0,0,1-.532,2.455,3.857,3.857,0,0,1-1.5,1.544,4.288,4.288,0,0,1-2.152.54,3.727,3.727,0,0,1-2.919-1.181,4.728,4.728,0,0,1-1.08-3.24V276.256a.335.335,0,0,0-.335-.336H932.4a.336.336,0,0,0-.335.336v16.185a11.036,11.036,0,0,0,1.122,5.1,8.362,8.362,0,0,0,3.13,3.417,9.617,9.617,0,0,0,9.677-.169,7.467,7.467,0,0,0,2.944-3.805h.27V301.5a.335.335,0,0,0,.335.335h7.48a.335.335,0,0,0,.335-.335V276.256A.336.336,0,0,0,957.021,275.92Z"
          transform="translate(-625.006 -27.846)"
          fill="inherit"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M626.063,263.9a16.944,16.944,0,0,0,10.781-15.379.4.4,0,0,0-.4-.407h-7.351a.4.4,0,0,0-.4.376,8.8,8.8,0,0,1-8.787,8.416V239.774a.338.338,0,0,0-.338-.338h-7.651a.338.338,0,0,0-.338.338v33.883a.338.338,0,0,0,.338.338h7.651a.338.338,0,0,0,.338-.338v-8.606a8.891,8.891,0,0,1,8.788,8.613.339.339,0,0,0,.337.331h7.474c.219,0,.343-.059.343-.344a16.841,16.841,0,0,0-2.561-8.768,1.126,1.126,0,0,0-.954-.531h-7.186A.236.236,0,0,1,626.063,263.9Z"
          transform="translate(-380.417 -0.001)"
          fill="inherit"
        />
        <rect
          id="Rectangle_1"
          data-name="Rectangle 1"
          width="8.555"
          height="34.559"
          rx="1.37"
          transform="translate(162.707 239.434)"
          fill="inherit"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M510.65,274.681a8.993,8.993,0,0,0-.905-.172,8.622,8.622,0,0,0-1.181-.084,5.776,5.776,0,0,0-3.7,1.257,7.147,7.147,0,0,0-2.295,3.89h-.27V275.1a.324.324,0,0,0-.324-.325h-7.67a.325.325,0,0,0-.325.325v25.27a.325.325,0,0,0,.325.324h7.906a.324.324,0,0,0,.324-.324v-13.58a5.266,5.266,0,0,1,.658-2.666A4.677,4.677,0,0,1,505,282.338a5.2,5.2,0,0,1,2.582-.641,14.4,14.4,0,0,1,1.713.118,9.248,9.248,0,0,1,1.193.216.323.323,0,0,0,.4-.315V275A.323.323,0,0,0,510.65,274.681Z"
          transform="translate(-290.67 -26.704)"
          fill="inherit"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M222.671,275.917h-8.437a.269.269,0,0,0-.26.2l-4.056,16.044-.529,2.081h-.27l-.68-2.707-3.846-15.417a.268.268,0,0,0-.261-.2h-8.283a.34.34,0,0,0-.322.449l6.682,19.665,1.642,4.832.562,1.65c.042.125.079.243.112.358.325,1.088.231,1.685-.035,2.031a2.277,2.277,0,0,1-1.444.8,10.539,10.539,0,0,1-1.623.117h-3.889a.239.239,0,0,0-.239.239v5.771a.239.239,0,0,0,.239.24h5.245a19.259,19.259,0,0,0,3.627-.293,6.514,6.514,0,0,0,2.682-1.18,9.183,9.183,0,0,0,2.243-2.653c.135-.227.275-.468.414-.726s.29-.544.439-.839a30.725,30.725,0,0,0,1.606-3.87l8.937-26.231A.269.269,0,0,0,222.671,275.917Z"
          transform="translate(-63.028 -27.843)"
          fill="inherit"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M129.883,248.074h-7.235a.185.185,0,0,1-.088-.349c1.277-.659,3.4-2.178,3.4-3.95v-4.047a.293.293,0,0,0-.293-.293h-7.95a.3.3,0,0,0-.3.3v4.157q0,.2-.027.391a4.3,4.3,0,0,1-3.966,3.784.338.338,0,0,0-.31.339v5.329a.337.337,0,0,0,.337.337h3.965v11.5a8.431,8.431,0,0,0,8.433,8.429h4.1a.311.311,0,0,0,.311-.311v-5.627a.311.311,0,0,0-.311-.311H128.3a2.339,2.339,0,0,1-2.338-2.338V254.068h3.919a.383.383,0,0,0,.383-.383v-5.227A.383.383,0,0,0,129.883,248.074Z"
          transform="translate(0 0)"
          fill="inherit"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M394.169,278.149a11.071,11.071,0,0,0-4-2.576,14.447,14.447,0,0,0-5.143-.877,13.469,13.469,0,0,0-6.854,1.678,11.64,11.64,0,0,0-4.51,4.686,14.716,14.716,0,0,0-1.6,7.025,15.013,15.013,0,0,0,1.595,7.15,11.053,11.053,0,0,0,4.551,4.609,14.51,14.51,0,0,0,7.058,1.614,16.1,16.1,0,0,0,6.069-1.055,10.6,10.6,0,0,0,4.222-2.971,9.328,9.328,0,0,0,1.993-4.11.326.326,0,0,0-.31-.4l-7.243-.142a.331.331,0,0,0-.306.2,3.431,3.431,0,0,1-.846,1.17,4.227,4.227,0,0,1-1.495.862,5.9,5.9,0,0,1-1.915.3,5.2,5.2,0,0,1-2.617-.625,4.273,4.273,0,0,1-1.7-1.757,5.5,5.5,0,0,1-.6-2.617v-.271h16.848a.324.324,0,0,0,.325-.325v-1.768a16.112,16.112,0,0,0-.92-5.632A11.567,11.567,0,0,0,394.169,278.149Zm-13.648,9.536c0-3.326.905-6.245,4.672-6.245,3.639,0,4.543,3.062,4.543,6.245Z"
          transform="translate(-197.617 -26.912)"
          fill="inherit"
        />
      </g>
    </svg>
  )
}

export default HeadTylerKaru
